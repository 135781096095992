#tasks,
.subtasks {
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0 1.2rem 2rem;
  gap: 5px;
}

.subtasks {
  margin-top: 5px;
  margin-bottom: 0;

  &:empty {
    display: none;
  }
}

#tasks h1 {
  margin-top: 0.4rem;
}

#tasks .task {
  margin: 0 0 0 -0.8rem;
}

#tasks .task .task-link {
  .item();

  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.55rem 0.8rem;
  gap: 0.75rem;
  width: 100%;
  cursor: pointer;
  z-index: 0;
  font-weight: 500 !important;
  font-size: 0.9rem;
  color: var(--text-color) !important;
  background: transparent;
  outline: none;
  border: none;
  text-decoration: none;
  margin: 0;

  .task-icon-link {
    display: inline-flex;

    svg,
    img {
      width: 1.4rem !important;
      height: 1.4rem !important;
      color: var(--text-color);

      * {
        transition: stroke-width var(--standard-transition);
      }
    }
  }

  .task-link-text {
    display: contents;
  }

  &--active {
    font-weight: 600 !important;
    cursor: default;

    svg * {
      stroke-width: 38px;
    }

    &::before {
      background-color: var(--item-background--active) !important;
    }

    &::after {
      box-shadow: none !important;
    }
  }
}
