/* ========================= repeatable elements ========================= */

.repeated-chunk {
  position: relative;
  border: 2px dashed var(--input-border);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.repeated-chunk .show-if-last {
  visibility: hidden;
}

.repeated-chunk.last .show-if-last {
  visibility: visible;
}

.repeated-chunk .show-if-not-last {
  visibility: visible;
}

.repeated-chunk.last .show-if-not-last {
  visibility: hidden;
}

.repeated-chunk .show-if-not-only {
  visibility: visible;
}

/* == nested repeatable elements / 2 deep == */
.repeated-chunk .repeated-chunk .show-if-last {
  visibility: hidden;
}

.repeated-chunk .repeated-chunk.last .show-if-last {
  visibility: visible;
}

.repeated-chunk .repeated-chunk .show-if-not-last {
  visibility: visible;
}

.repeated-chunk .repeated-chunk.last .show-if-not-last {
  visibility: hidden;
}

.repeated-chunk .repeated-chunk .show-if-not-only {
  visibility: visible;
}

/* == nested repeatable elements / 3 deep == */
.repeated-chunk .repeated-chunk .repeated-chunk .show-if-last {
  visibility: hidden;
}

.repeated-chunk .repeated-chunk .repeated-chunk.last .show-if-last {
  visibility: visible;
}

.repeated-chunk .repeated-chunk .repeated-chunk .show-if-not-last {
  visibility: visible;
}

.repeated-chunk .repeated-chunk .repeated-chunk.last .show-if-not-last {
  visibility: hidden;
}

.repeated-chunk .repeated-chunk .show-if-not-only {
  visibility: visible;
}

/*
    <DIV>s marked with to-be-removed is used in conjunction with repeatable.jelly and hetero-list.jelly
    and represents a master copy that gets pulled out from HTML, then inserted later upon demand multiple times
    when the user does "Add".
*/
div.to-be-removed {
  display: none;
}

/* ========================= D&D support in heterogenous/repeatable lists = */

.hetero-list-container.with-drag-drop .repeated-chunk,
.repeated-container.with-drag-drop .repeated-chunk {
  margin-bottom: 1rem;
}

// SortableJS drag & drop classes
.repeated-chunk--sortable-ghost {
  height: 100px;
  width: 100%;
  overflow: hidden;
}

.repeated-chunk--sortable-chosen {
  width: 100%;
  height: 100px;
  background-color: transparent;
  border: 2px solid var(--primary);

  & > * {
    display: none;
  }
}

.repeated-chunk {
  & > div > *:last-of-type {
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    margin-top: -0.4rem;
    margin-bottom: 0.75rem;

    .dd-handle {
      position: relative;
      width: 30px;
      height: 30px;
      overflow: hidden;
      margin-right: 0.75rem;
      cursor: move;
      margin-left: -6px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: var(--text-color);
        border-radius: var(--form-input-border-radius);
        opacity: 0;
        transition: var(--standard-transition);
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 4px;
        bottom: 0;
        right: 4px;
        background-color: var(--text-color);
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EReorder Three%3C/title%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M96 256h320M96 176h320M96 336h320'/%3E%3C/svg%3E");
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
      }

      &:hover {
        &::before {
          opacity: 0.1;
        }
      }
    }
  }

  // TODO: Update/remove when .jenkins-button PR is merged
  .repeatable-delete {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: none;
    outline: none;
    margin-left: auto;
    color: var(--red);
    z-index: 0;
    background: transparent;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: currentColor;
      border-radius: 100px;
      z-index: -1;
      opacity: 0.075;
      transition: var(--standard-transition);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      box-shadow: 0 0 0 10px transparent;
      border-radius: 100px;
      z-index: -1;
      opacity: 0.075;
      transition: var(--standard-transition);
    }

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      &::before {
        opacity: 0.1;
      }
    }

    &:active,
    &:focus {
      &::before {
        opacity: 0.15;
      }

      &::after {
        box-shadow: 0 0 0 5px var(--red);
      }
    }
  }
}
