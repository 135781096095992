@import "theme.less";

// Generates a series of color override classes and their variations,
// e.g. .jenkins-!-color-blue, .jenkins-!-color-light-blue, .jenkins-!-color-dark-blue
each(@colors, {
  .jenkins-\!-color-light-@{key} {
    color: ~"var(--light-@{key})" !important;
  }
  .jenkins-\!-color-@{key} {
    color: ~"var(--@{key})" !important;
  }
  .jenkins-\!-color-dark-@{key} {
    color: ~"var(--dark-@{key})" !important;
  }
});

each(@semantics, {
  .jenkins-\!-@{key}-color {
    color: @value !important;
  }
});

// Deprecated - don't use the below classes
.greyed {
  color: #999;
}

.redbold {
  color: var(--red);
  font-weight: bold;
}

.greenbold {
  color: var(--green);
  font-weight: bold;
}
