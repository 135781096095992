.jenkins-button {
  appearance: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  margin: 0;
  padding: 0.5rem 0.8rem;
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: none !important;
  background: transparent;
  color: var(--text-color) !important;
  z-index: 0;
  border-radius: 6px;
  cursor: pointer;
  min-height: 36px;
  text-shadow: 0 1px 0 var(--background);
  white-space: nowrap;
  gap: 1ch;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: 0.075;
    border-radius: inherit;
    transition: 0.2s ease;
  }

  &::before {
    background: currentColor;
  }

  &::after {
    box-shadow: inset 0 -1px 0 rgb(125, 125, 125), 0 0 0 10px transparent;
  }

  &:hover {
    &::before {
      opacity: 0.1125;
    }
  }

  &:active,
  &:focus {
    &::before {
      opacity: 0.15;
    }

    &::after {
      box-shadow: inset 0 -1px 0 rgb(125, 125, 125), 0 0 0 5px currentColor;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.jenkins-button--primary {
  color: var(--button-color--primary) !important;
  font-weight: 600;
  text-shadow: none;

  &::before,
  &::after {
    color: var(--primary);
  }

  &::before {
    opacity: 1;
  }

  &::after {
    opacity: 0.2;
  }

  &:hover {
    &::before {
      opacity: 0.9;
    }
  }

  &:active,
  &:focus {
    &::before {
      opacity: 0.8;
    }
  }
}

.jenkins-button--transparent {
  &::before {
    opacity: 0;
  }

  &::after {
    color: transparent;
    box-shadow: inset 0 -1px 0 transparent, 0 0 0 10px transparent;
  }

  &:hover,
  &:active,
  &:focus {
    &::after {
      color: currentColor;
    }
  }
}

@variants: {
  destructive: var(--red);
};

each(@variants, {
  .jenkins-button--@{key} {
    color: @value !important;
  }
});

.jenkins-validate-button__container {
  &__status {
    .validation-error-area {
      min-height: 36px !important;
    }
  }

  .validation-error-area--visible {
    margin-top: 0;
  }

  & > span {
    float: right;
  }
}

.jenkins-copy-button {
  .jenkins-copy-button__icon {
    position: relative;
    width: 0.9rem;
    height: 1.125rem;
    transition: var(--standard-transition);

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 75%;
      height: 75%;
      border: 0.1rem solid currentColor;
      border-radius: 0.2rem;
      transition: var(--standard-transition);
    }

    &::before {
      top: 4%;
      left: 1%;
      clip-path: polygon(
        100% 0,
        100% 22.5%,
        22.5% 22.5%,
        32.5% 100%,
        0 100%,
        0 0
      );
    }

    &::after {
      bottom: 4%;
      right: 1%;
    }
  }

  &:hover {
    .jenkins-copy-button__icon {
      &::before {
        transform: translate(7%, 4.5%);
      }

      &::after {
        transform: translate(-7%, -4.5%);
      }
    }
  }

  &:active {
    .jenkins-copy-button__icon {
      transform: scale(0.85);
    }
  }
}
