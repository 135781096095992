@import "../abstracts/theme.less";

.page-header {
  display: block;
  display: flex;
  align-items: center;
  height: 3.5rem;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  background-color: var(--header-bg-classic);
}

.page-header > * {
  margin-right: 0.75rem;
}

.page-header__brand {
  display: inline-block;
  height: 3.5rem;
  position: relative;
  flex: 1; // push controls to the end of the block
}

// Need to use the element selector to increase weight otherwise it will be overriden by the
// a:visited selector if it is declared later
// Only styled by the overrides with the new UI enabled
a.page-header__brand-link {
  display: none;
}

.page-header__brand-name {
  color: inherit;
}

.page-header__brand-image {
  height: 2rem;
  width: 1.5rem;
  margin-right: 0.75rem;
}

.page-header__am-wrapper {
  height: 100%;
  padding: 0.5rem 0;
}

.page-header__hyperlinks {
  display: flex;
  align-items: center;
}

.page-header__hyperlinks a {
  --text-color: var(--header-link-color);

  display: inline-flex;
  align-items: center;

  // need to override an existing rule
  /* stylelint-disable declaration-block-no-shorthand-property-overrides */
  padding-right: 0.5rem;
  padding: 0.5rem;
  margin-right: 0 !important;
  font-weight: bold;
  outline-color: var(--header-link-outline);

  &:link,
  &:visited {
    color: var(--header-link-color);
    border-radius: var(--header-item-border-radius);
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &.mouseIsOverMenuSelector {
    color: var(--header-link-color-active);
    background-color: var(--header-link-bg-classic-hover);
    text-decoration: underline;
    text-decoration-color: var(--header-link-color-active);
  }

  &:active {
    background-color: var(--header-link-bg-classic-active);
  }

  .jenkins-menu-dropdown-chevron {
    position: relative;
    top: unset !important;
    right: unset !important;
    margin-left: 0.5rem;

    &::after {
      opacity: 1;
    }
  }
}

.page-header__hyperlinks a span {
  font-weight: bold;

  &:not(:first-child) {
    margin-left: 0.25rem;
  }
}

/* Search box */

// Style it through the id selector to override the YUI selectors set by
// the YUI Autocomplete module
#searchform {
  position: relative;
  font-family: var(--font-family-sans);
  display: inline-flex;
  height: 2.5rem;
}

// Need to add the id selector to override the ".yui-skin-sam .yui-ac-input" set by
// the YUI Autocomplete module
#search-box.main-search__input {
  position: static;
  padding: 0.25rem 2.5rem;
  margin: 0;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: bold;
  color: var(--search-input-color);
  border-radius: var(--form-input-border-radius);
  border: 2px solid var(--header-search-border);
  outline: none;
  box-shadow: 0 0 0 10px transparent;
  transition: 0.2s ease;

  &::placeholder {
    font-weight: normal;
  }

  &:active,
  &:focus {
    border-color: var(--focus-input-border);
    box-shadow: 0 0 0 5px var(--focus-input-glow);
  }
}

.main-search__icon-leading,
.main-search__icon-trailing {
  position: absolute;
  display: inline-flex;
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.main-search__icon-leading {
  left: 0;
  pointer-events: none;

  svg {
    width: 16px;
    height: 16px;
  }
}

.main-search__icon-trailing {
  right: 0;
  outline-color: var(--header-link-outline);

  &:link,
  &:visited {
    color: var(--search-input-color);
  }

  &:hover,
  &:focus {
    color: var(--header-link-bg-classic-hover);
  }

  &:active {
    fill: var(--header-link-bg-classic-active);
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

#search-box-completion {
  text-align: left;
  width: 25em;
  position: absolute;
  z-index: 1000;
}

#search-box-completion ul {
  padding: 0.75rem 0;
  width: 100%;
  margin: 0;
  list-style: none;
}

#search-box-completion li {
  white-space: nowrap;
  padding: 0.25rem 1.25rem;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  overflow: hidden;
  text-overflow: ellipsis;
}

#search-box-sizer {
  position: absolute;
  visibility: hidden;
  min-width: 15rem;
  max-width: calc(100vw - 500px);
}
