.jenkins-search {
  position: relative;
  max-width: 420px;

  &__input {
    appearance: none;
    display: block;
    background: var(--input-color);
    border: 2px solid var(--input-border);
    border-radius: var(--form-input-border-radius);
    width: 100%;
    margin: 0;
    padding: 0 4px 0 29px;
    line-height: 30px;
    box-shadow: var(--form-input-glow);
    transition: var(--standard-transition);

    // Safari adds unwanted padding - let's remove it
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 1rem;
      width: 1rem;
      margin-right: 2px;
      background: currentColor;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm75.31 260.69a16 16 0 11-22.62 22.62L256 278.63l-52.69 52.68a16 16 0 01-22.62-22.62L233.37 256l-52.68-52.69a16 16 0 0122.62-22.62L256 233.37l52.69-52.68a16 16 0 0122.62 22.62L278.63 256z'/%3E%3C/svg%3E");
      mask-size: contain;
      mask-repeat: no-repeat;
      opacity: 0;
      pointer-events: none;
      transform: scale(0.8);
      transition: var(--standard-transition);
      cursor: pointer;

      &:hover {
        opacity: 0.75 !important;
      }

      &:active {
        opacity: 1 !important;
      }
    }

    &:hover {
      border-color: var(--input-border-hover);
    }

    &:active,
    &:focus {
      outline: none;
      border-color: var(--focus-input-border);
      box-shadow: var(--form-input-glow--focus);

      &::-webkit-search-cancel-button {
        opacity: 0.5;
        pointer-events: all;
        transform: scale(1);
      }
    }
  }

  &__icon {
    position: absolute;
    top: 9px;
    left: 9px;
    width: 16px;
    height: 16px;
    fill: var(--input-border-hover);
    transition: var(--standard-transition);
  }

  &::before {
    content: "";
    position: absolute;
    top: 17px;
    left: 17px;
    width: 0;
    height: 0;
    color: inherit;
    border: 2px solid currentColor;
    border-radius: 100%;
    opacity: 0;
    transition: var(--standard-transition);
  }

  &::after {
    content: "";
    position: absolute;
    top: 17px;
    left: 17px;
    width: 0;
    height: 0;
    color: inherit;
    border: 2px solid currentColor;
    border-radius: 100%;
    clip-path: inset(0 0 50% 50%);
    opacity: 0;
    transition: var(--standard-transition);
    animation: loading-spinner 1s infinite linear;

    @media (prefers-reduced-motion) {
      animation-duration: 2s;
    }
  }

  &--loading {
    .jenkins-search__icon {
      opacity: 0;
      transform: scale(0);
    }

    &::before {
      opacity: 0.2;
      top: 9px;
      left: 9px;
      width: 16px;
      height: 16px;
    }

    &::after {
      opacity: 1;
      top: 9px;
      left: 9px;
      width: 16px;
      height: 16px;
    }
  }

  &:focus-within {
    .jenkins-search__icon {
      fill: var(--focus-input-border);
    }
  }
}
