.jenkins-app-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--section-padding);

  .jenkins-app-bar__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 36px;
  }

  .jenkins-app-bar__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--section-padding);
    min-height: 36px;
    gap: 1rem;

    .jenkins-search {
      min-width: 260px;
    }
  }

  &--border {
    margin-bottom: var(--section-padding);
    padding-bottom: var(--section-padding);
    border-bottom: 2px solid var(--panel-border-color);
  }

  h1,
  h2 {
    margin: 0;
    font-size: 1.6rem;
  }
}
