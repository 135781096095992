.jenkins-icon-size {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;

  &__items {
    display: flex;
    align-items: center;

    ol {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      align-items: center;
    }

    li {
      width: 32px;
      margin-right: 0.2rem;

      .yui-button {
        margin: 0 !important;
        padding: 0 !important;
        width: 32px !important;
        height: 32px !important;
        min-width: 0 !important;
      }
    }

    .jenkins-icon-size__items-item {
      height: 32px;
      width: 32px;
      border-radius: 4px;
      line-height: 32px;
      background: var(--btn-link-bg--hover);
      text-align: center;
      font-weight: var(--btn-link-font-weight);
      font-size: var(--btn-font-size);
    }

    & > * {
      margin-right: 0.5rem;
    }
  }
}
