.tabBarFrame {
  position: relative;
}

.tabBar {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  background: var(--tabs-background);
  border-radius: var(--tabs-border-radius);
  padding: 2.5px;
  margin-bottom: 2rem;

  .tab {
    float: left;
  }
}

.tabBar .tab a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3rem;
  text-decoration: none;
  margin: 2.5px;
  padding: 0.4rem 1.2rem;
  border-radius: 100px;
  background: var(--tabs-item-background);
  color: var(--tabs-item-foreground);
  font-weight: 600;
  font-size: 0.85rem;
  transition: var(--standard-transition);
  cursor: pointer;

  &:hover {
    background: var(--tabs-item-background--hover);
    color: var(--tabs-item-foreground--hover);
  }

  &:active,
  &:focus {
    outline: none;
    background: var(--tabs-item-background--active);
    color: var(--tabs-item-foreground--active);
  }
}

.tabBar .tab .addTab svg {
  width: 1.25rem;
  height: 1.25rem;
}

.tabBar .tab [type="radio"] {
  display: none;
}

.tabBar .tab.active a {
  background: var(--tabs-item-background--selected);
  color: var(--tabs-item-foreground--selected);
  z-index: 2;
  cursor: default;
}

.tabBarBaseline {
  border-top: var(--tab-baseline-width) solid var(--tab-baseline-color);
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;

  /* Baseline is hidden by default.  See next rule fo adding visibility. */
  display: var(--tab-baseline-default-display, none);
}

.tabBarFrame.showBaseline .tabBarBaseline {
  display: block;
}

.jenkins-tab-pane__title {
  font-size: 1.6rem;
  margin: 2.2rem 0 1.4rem;
  padding: 0;
}
